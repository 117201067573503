/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import axios from "axios";
import yaml from "js-yaml";

class YamlLoader {
    
    load = async(path) => {
        try {
            //load messages from yml
            let resp = await axios.get(path);
            if(resp.data){
              //parse YML
              let parsedYaml = yaml.safeLoad(resp.data);
              let parsedYamlType = Object.prototype.toString.call(parsedYaml)
              if(parsedYamlType === "[object Array]" || parsedYamlType === "[object Object]"){
                return parsedYaml;
              }
              else{
                return null;
              }
            }
        
          } catch{
          }
          return null;
    }

}
  
export default YamlLoader;
  
/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import React from 'react'
import ArticleProvider from './ArticleProvider'
import { Card, Spinner } from 'react-bootstrap'
import Article from './Article'

class ArticlePage extends React.Component {

    MAX_LATEST = 10

    provider = null
    lang = 'en'

    constructor(props){
        super(props)
        if(this.props.lang) this.lang = this.props.lang

        this.provider = new ArticleProvider(this.lang)
        this.msg = window.reactSiteTranslations

        this.state = {
            latestArticles : null,
            olderArticles : null,
            articleText : null,
            articleTitle : null,
            articleDate : null
        }
    }

    componentDidMount = async() => {

        if(!this.provider) return

        if(!this.props.articlesId) return


        await this.provider.loadArticles()
        if(!this.provider.articles) return

        try {
            if(this.props.articlesId === 'home'){
                await this.loadArticlesHome()
            }
            else{
                await this.loadArticlesItem(this.props.articlesId)
            }
        } 
        catch (error) {
            console.log(error)
        }
        
    }

    loadArticlesItem = async(articlesId) => {
        let articlesIndex = this.provider.articles
        let article;
        //find the id
        for(let articlesEntry of articlesIndex){
            let curId = articlesEntry.title.replace(/[^a-z0-9]/gi,'-')
            if(curId === articlesId){ //item found
                article = new Article(articlesEntry, this.lang)
                await article.load()
                break
            }
        }
        this.setState({article:article})
    }


    loadArticlesHome = async() => {
        let latestArticlesIndex = this.provider.articles.slice(0,this.MAX_LATEST)
        let latestArticles = []
        for(let articlesEntry of latestArticlesIndex){
            let cardInfo = articlesEntry
            let article = new Article(articlesEntry, this.lang);
            await article.load()
            if(!article.error){
                cardInfo.description = article.summary
                cardInfo.id = articlesEntry.title.replace(/[^a-z0-9]/gi,'-')

                latestArticles.push(cardInfo)
            }
        }

        let olderArticles = []
        if(this.provider.articles.length > this.MAX_LATEST){
            olderArticles = this.provider.articles.slice(this.MAX_LATEST)
        }

        this.setState({latestArticles:latestArticles, olderArticles:olderArticles})

    }

    render(){
        if(!window.reactSiteTranslations || !this.provider.articles) return <></>
        let urllang = "/"+this.lang
        if(!this.msg.LangSelectors) urllang = ""
        let article = this.state.article
        let markup = <></>
        let latestArticlesMarkup = <></>
        let olderArticlesMarkup = <></>
        if(this.props.articlesId === 'home'){
            if(!this.state.latestArticles) return <Spinner animation="grow" />
            else {
                latestArticlesMarkup = this.state.latestArticles.map((entry,index)=>{
                return <span key={`latest-articles-${index}`}>
                    <Card  className='rbs-article-card'>
                        <Card.Body>
                            <a href={`${urllang}/articles/${entry.id}`}>
                                <Card.Title>
                                    {entry.title}
                                </Card.Title>
                            </a>
                            <Card.Subtitle>
                                    {entry.date.toLocaleDateString()}
                                </Card.Subtitle>
                            <Card.Text>
                                {entry.description}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </span>
                })
            }

            if(this.state.olderArticles && this.state.olderArticles.length > 0){
                olderArticlesMarkup = this.state.olderArticles.map((entry,index)=>{
                    let articlesId = entry.title.replace(/[^a-z0-9]/gi,'-')

                    return <span key={`older-articles-${index}`}>
                        <li>
                            <a href={`${urllang}/articles/${articlesId}`}>
                                {entry.date.toLocaleDateString()} - {entry.title}
                            </a>
                        </li>
                    </span>
                })
                olderArticlesMarkup = <>
                    <hr></hr>
                    <h5>{this.msg.OlderArticles[this.lang]}</h5>
                    <ul>{olderArticlesMarkup}</ul>
                </>
            }


            markup = <>
                {latestArticlesMarkup}
                {olderArticlesMarkup}
            </>
        }
        else if(article){
            
            if(article.title){
                document.title = `${article.title} | ${this.props.manifest.name}`
            }
            else{
                document.title = this.msg.NotFound[this.lang];
            }
            markup = <>
                <h3>{article.title}</h3>
                <h5>{article.date}</h5>
                <div className="mt-3" dangerouslySetInnerHTML={{__html:article.html}}></div>
            </>
        }

        return (
            <>{markup}</>
        )
    }

}
  
export default ArticlePage
  
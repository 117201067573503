/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */
import React from "react";

class LangSelector extends React.Component {
    render(){
      let pageId = "home";
      let pageType = "pages";
      if(this.props.pageId){
        pageId = this.props.pageId;
      }
      if(this.props.pageType){
        pageType = this.props.pageType;
        if(pageType === "articles") pageId = "home"
      }
      let content = ""
      let languages = this.props.msg.LangSelectors
      let keys
      if(languages) keys = Object.keys(languages)
      if(keys && keys.length > 1){
        content = keys.map((key) => {
            return <a href={`/${key}/${pageType}/${pageId}`} key={"selector-"+key} data-testid={key} className="mr-1">
                <img alt={languages[key]} src={"/content/img/"+key+".png"}></img>
              </a>
        })
      }
      return (
          <p className="text-right">
            {content}   
          </p>    
      );
    }

}
  
export default LangSelector;
  
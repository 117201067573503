/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import React from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown/with-html";
import gfm from "remark-gfm";

class BasePage extends React.Component {

    componentDidMount = async() => {
        let pageId = this.props.pageId;
        let lang = this.props.lang;

        if(pageId && lang){
            let textUrl = `/content/pages/${lang}/${pageId}.md`;
            let response = await axios.get(textUrl);
            this.setState({content:response.data});
        }
    }


    render(){
        
        if(!this.state || !this.state.content) return <div></div>;

        return <ReactMarkdown plugins={[gfm]} source = {this.state.content} allowDangerousHtml={true}></ReactMarkdown>;
    }

}
  
export default BasePage;
  
/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import YamlLoader from "./YamlLoader";

class ArticleProvider {

    articles = [];
    lang = "en";
    
    constructor(langArg){
        this.lang = langArg;
    }

    loadArticles = async() => {
        let yamlLoader = new YamlLoader();
        this.articles = await yamlLoader.load(`/content/articles/${this.lang}/articles-index.yml`);
    }



}
  
export default ArticleProvider;
  